<template>
  <div
    class="one-col-box"
    :class="{
      wide: isWide,
      'even-wider': isEvenWider,
      'start-higher': startHigher,
      'no-transform': noTransform,
      'rounded-corners': roundedCorners,
      'no-padding': noPadding,
      'no-background': noBackground
    }"
    :style="{ marginTop }"
  >
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      marginTop: 50
    }
  },
  props: {
    isWide: {
      type: Boolean,
      default: false
    },
    isEvenWider: {
      type: Boolean,
      default: false
    },
    startHigher: {
      type: Boolean
    },
    noTransform: {
      type: Boolean
    },
    applyTopMargin: {
      type: Boolean
    },
    addSpaceToTop: {
      type: Boolean
    },
    roundedCorners: {
      type: Boolean
    },
    noPadding: {
      type: Boolean
    },
    noBackground: {
      type: Boolean
    }
  },
  mounted() {
    this.$root.$on('header-height-changed', this.updateTopMargin)
  },
  beforeDestroy() {
    this.$root.$off('header-height-changed', this.updateTopMargin)
  },
  methods: {
    updateTopMargin(headerHeight) {
      if (!this.$props.applyTopMargin) {
        return
      }

      if (this.$props.addSpaceToTop) {
        headerHeight += 50
      }

      this.marginTop = headerHeight + 'px'
    }
  }
}
</script>

<style lang="scss" scoped>
.one-col-box {
  min-height: 600px;
  max-width: 960px;
  margin: 0 auto;
  background-color: #fff;

  &.wide {
    max-width: 980px;
  }

  &.even-wider {
    max-width: 1018px;
  }

  &.rounded-corners {
    border-radius: 6px;
  }

  &.no-background {
    background-color: rgba(0, 0, 0, 0);
  }

  &.no-padding {
    > .content {
      padding: 0;
    }
  }

  > .content {
    padding: 0px 20px;
    overflow: hidden;
  }
}

@media (min-width: $desktop-min-width) {
  .one-col-box {
    transform: translateY(-70px);

    &.start-higher {
      transform: translateY(-78px);
    }

    &.no-transform {
      transform: none;
      margin-bottom: 50px;
    }

    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  }
}
</style>
